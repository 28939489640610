import {
  JSX,
  useMemo,
  CSSProperties,
} from "react";
import { Link } from "react-router-dom";
import TagsColumn from "./TableColumns/TagsColumn/TagsColumn";
import RatingColumn from "./TableColumns/RatingColumn/RatingColumn";
import SettingsColumn from "./TableColumns/SettingsColumn/SettingsColumn";
import { translateTableTitle } from "./utils/translateTableTitle";
import {
  Table,
  MenuProps,
  TableProps,
} from "antd";
import { SorterResult } from "antd/es/table/interface";
import type { ColumnsType } from "antd/es/table";
import css from "./CustomTable.module.css";

interface CustomTableProps<T> {
  data: T[];
  linkTo?: string;
  tagKeys?: string[];
  linkKeys?: string[];
  className?: string;
  isLoading?: boolean;
  rowStyles?: (record: T, index: number) => CSSProperties;
  ratingKeys?: string[];
  subheadKeys?: string[];
  tableStyles?: CSSProperties;
  columnWidth?: Record<string, string>[]
  hasSettings?: boolean;
  settingsItems?: MenuProps["items"];
  sortableColumns?: Record<string, boolean>;
  sortableFunction?: (sortField: string) => void;
}

const generateRowKey = <T extends Record<string, any>>(record: T): string => {
  return String(record["uuid"]);
};

const CustomTable = <T extends object>({
  data,
  linkTo,
  tagKeys,
  linkKeys,
  className,
  isLoading,
  rowStyles,
  ratingKeys,
  subheadKeys,
  tableStyles,
  columnWidth,
  hasSettings = false,
  settingsItems,
  sortableColumns,
  sortableFunction,
}: CustomTableProps<T>): JSX.Element => {
  const getRendererType = (key: string, value: any, record: any): JSX.Element => {
    if (tagKeys?.includes(key)) {
      return (
        <Link to={`/${linkTo}/${record?.uuid}`} className={`${css.tableLink} flex flex-wrap items-center`}>
          <TagsColumn tags={value} />
        </Link>
      );
    }

    if (linkKeys?.includes(key)) {
      return (
        <Link to={`/${linkTo}/${record?.uuid}`} className={css.tableLink}>
          {value}
        </Link>
      );
    }

    if (ratingKeys?.includes(key)) {
      return (
        <Link to={`/${linkTo}/${record?.uuid}`} className={css.tableLink}>
          <RatingColumn rating={value} />
        </Link>
      );
    }

    if (subheadKeys?.includes(key)) {
      const [head, subhead] = value.split("/");
      return (
        <Link to={`/${linkTo}/${record?.uuid}`} className={css.tableLink}>
          <span className="table-row-head">{head}</span>
          {subhead}
        </Link>
      );
    }

    return <span>{value}</span>;
  };

  const columns: ColumnsType<T> = useMemo(() => {
    if (!data.length) return [];

    const allKeys: string[] = Array.from(new Set(data.flatMap((record: T) => Object.keys(record))));

    const generatedColumns: ColumnsType<T> = allKeys.map((key: string, index: number) => {
      const isSortable: boolean = sortableColumns?.[key] || false;

      return {
        title: <span className={css.tableHeader}>{translateTableTitle(key)}</span>,
        dataIndex: key,
        render: (_: any, record: T) => {
          const value: T[keyof T] = record[key as keyof T];
          return getRendererType(key, value, record as any);
        },
        sorter: isSortable,
        width: columnWidth?.[index]?.[key] ?? "",
      };
    });

    if (hasSettings && !!settingsItems?.length) {
      generatedColumns.push({
        title: "",
        key: "settings",
        render: (_: any, record: T): JSX.Element => <SettingsColumn menuItems={settingsItems} record={record} />,
        align: "center",
        width: "1%",
      });
    }

    // Обрезаем первый столбец таблицы чтоб не появлялся в UI
    // Первый столбец содержит UUID для перехода в карточку
    return generatedColumns.slice(1);
  }, [data, hasSettings, settingsItems, sortableColumns]);

  const rowClassName = (record: T, index: number): string => {
    const style: CSSProperties = rowStyles ? rowStyles(record, index) : {};

    return style as string;
  };

  const handleTableChange: TableProps<T>["onChange"] = (_, __, sorter: SorterResult<T> | SorterResult<T>[]): void => {
    if (sortableFunction && sorter && "field" in sorter && "order" in sorter) {
      const sortField: string = sorter.order === "ascend" || !sorter.order
        ? sorter.field as string
        : `-${sorter.field as string}`;

      sortableFunction(sortField);
    }
  };

  return (
    <Table
      style={tableStyles}
      className={`${className}`}
      rowKey={generateRowKey}
      columns={columns}
      dataSource={data}
      onChange={handleTableChange}
      rowClassName={rowClassName}
      loading={isLoading}
      showSorterTooltip={false}
      pagination={false}
    />
  );
};

export default CustomTable;
