import {
  FC,
  JSX, useEffect, useState
} from "react";
import {
  customSelectStyle,
  supplierCardHeaderOptions,
} from "./utils/supplierCardHeaderUtils";
import ReturnButton from "../../../components/ReturnButton/ReturnButton";
import { CustomInput } from "../../../components/CustomInput/CustomInput";
import { getDocData, getDocumentFailure } from "../../../actions/documents.actions";
import { ReactComponent as StarIcon } from "../../../assets/icons/CustomTable/star.svg";
import { DownOutlined } from "@ant-design/icons";
import { ConfigProvider } from "antd";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import AssessingSupplierLink from "../../../components/AssessingSupplierLink/AssessingSupplierLink";

interface ISupplierCardHeaderProps {
  supplierUuid: string;
  supplierName: string;
  supplierMark: number;
}

const selectActionName: JSX.Element = <span className="text-white">Добавить оценку</span>;

enum HeaderActions {
  AddMark = "mark-form",
  SupplierLink = "link-form",
}

const SupplierCardHeader: FC<ISupplierCardHeaderProps> = ({
  supplierUuid,
  supplierName,
  supplierMark,
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const location: string = useLocation()?.pathname;
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const splittedPath: string[] = location?.split("/");
    const hasSupplierLink: boolean = splittedPath?.includes("supplier-link");

    if (hasSupplierLink) {
      const supplierLink: string = splittedPath?.slice(-1)?.toString();

      setIsModalOpen(true);
      dispatch(getDocData("supplier-counteragent", supplierLink));
    }
  }, [location]);

  const handleSelect = (selectAction: string | JSX.Element) => {
    switch (selectAction) {
      case HeaderActions.AddMark:
        return navigate(`/${HeaderActions.AddMark}/${supplierUuid}/card`);
      case HeaderActions.SupplierLink:
        return navigate(`supplier-link/${supplierUuid}`);
      default:
        return "";
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <ReturnButton linkTo="/suppliers" action={getDocumentFailure} />
        <h1 className="header mr-3">{supplierName}</h1>
        {!!supplierMark && (
          <div className="flex items-center">
            <StarIcon className="mr-1.5" />
            <span>{supplierMark?.toFixed(1)}</span>
          </div>
        )}
      </div>
      <ConfigProvider theme={customSelectStyle}>
        <CustomInput
          id="supplierCardHeaderAction"
          type="select"
          className="text-white"
          options={supplierCardHeaderOptions}
          selectValue={selectActionName}
          placeholder="Добавить оценку"
          suffixicon={<DownOutlined className="text-white" />}
          dropdownStyle={{ width: "305px" }}
          onSelect={handleSelect}
        />
      </ConfigProvider>
      <AssessingSupplierLink
        isCard
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default SupplierCardHeader;
