import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { setupResponseInterceptor } from "./utils/axios";
import { getEnvironment } from "./utils/getEnvironment";
import locale from "antd/locale/ru_RU";
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";
import { myCustomTheme } from "./constants/customTheme";
import "./index.css";

const SENTRY_KEY: string | undefined = process.env.REACT_APP_API_SENTRY_KEY;

SENTRY_KEY && Sentry.init({
  dsn: SENTRY_KEY,
  environment: getEnvironment(),
  integrations: [
    Sentry.dedupeIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserApiErrorsIntegration(),
  ],
  tracesSampleRate: 1.0,
});

setupResponseInterceptor(store);

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={myCustomTheme} locale={locale}>
        <Sentry.ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
