export const validateActivityRegion = (regions: string[], isRegion?: boolean) => {
  return async (_: any, _value: string[]) => {
    if (!regions?.length) {
      console.log("value regionsAc", regions);
      return Promise.reject(new Error(`Пожалуйста, выберите ${isRegion ? "регион деятельности" : "категорию"}`));
    }

    return Promise.resolve();
  };
};
