import {
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SCHEMA_LIST_SUCCESS,
  GET_DOCUMENT_SCHEMA_LIST_FAILURE,
} from "../constants/actionTypes";
import { DocumentListResultsType } from "app/types";
import { DocumentsActionTypes } from "../actions/documents.actions";

export type InitialDocumentStateType = {
  document: DocumentListResultsType | null;
  schemasList: DocumentListResultsType[];
};

const initialState: InitialDocumentStateType = {
  document: null,
  schemasList: [],
};

const document = (
  state: InitialDocumentStateType = initialState,
  action: DocumentsActionTypes
): InitialDocumentStateType => {
  switch (action.type) {
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload.document,
      };
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        document: null,
      };
    case GET_DOCUMENT_SCHEMA_LIST_SUCCESS:
      return {
        ...state,
        schemasList: action.payload.schemasList,
      };
    case GET_DOCUMENT_SCHEMA_LIST_FAILURE:
      return {
        ...state,
        schemasList: [],
      };
    default:
      return state;
  }
};

export default document;
