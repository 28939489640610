import {
  FC,
  JSX,
} from "react";
import {
  Modal,
  ButtonProps,
} from "antd";

interface ICustomModalProps {
  okText?: string;
  children?: JSX.Element;
  handleOk?: () => void;
  className?: string;
  cancelText?: string;
  modalTitle?: string;
  isCentered?: boolean;
  isModalOpen?: boolean;
  handleCancel?: () => void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

const CustomModal: FC<ICustomModalProps> = ({
  okText,
  children,
  handleOk,
  className,
  cancelText,
  modalTitle = "Модальное окно",
  isCentered,
  isModalOpen,
  handleCancel,
  okButtonProps,
  cancelButtonProps,
}): JSX.Element => {
  return (
    <Modal
      className={className}
      title={modalTitle}
      open={isModalOpen}
      onOk={handleOk}
      okText={okText}
      centered={isCentered}
      onCancel={handleCancel}
      cancelText={cancelText}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
