export const validateInn = (
  isSupplierHasBeenCreated: boolean | null,
  isSupplierNotFound: boolean | null,
  isMarkForm?: boolean,
) => {
  return async (_: any, value: string) => {
    if (isSupplierNotFound && !isMarkForm) {
      return Promise.reject(new Error("Инн не найден"));
    }

    if (isSupplierHasBeenCreated && !isMarkForm) {
      return Promise.reject(new Error("Поставщик уже создан"));
    }

    if (!isSupplierHasBeenCreated && isSupplierHasBeenCreated !== null && isMarkForm) {
      return Promise.reject(new Error("Поставщик не найден"));
    }

    if (value && !/^[0-9]+$/.test(value)) {
      return Promise.reject(new Error("ИНН должен содержать только цифры"));
    }

    return Promise.resolve();
  };
};
