import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { DocumentListResultsType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { getDocData } from "../../actions/documents.actions";
import { AppStateType } from "../../reducers/mainReducer";
import SupplierCardHeader from "./SupplierCardHeader/SupplierCardHeader";
import SupplierCardInfo from "./SupplierCardInfo/SupplierCardInfo";
import MarksList from "../MarksList/MarksList";
import { Tabs, TabsProps } from "antd";
import css from "./SupplierCard.module.css";

interface ISupplierCardProps {}

enum SupplierCardItems {
  GeneralInfo = "1",
  Marks = "2",
}

enum Paths {
  Marks = "marks",
  Default = "",
};

const SupplierCard: FC<ISupplierCardProps> = (): JSX.Element => {
  const [activeTabKey, setActiveTabKey] = useState<string>(SupplierCardItems.GeneralInfo);

  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const document: DocumentListResultsType | null = useSelector((state: AppStateType) => state.document.document);

  const {
    inn,
    kpp,
    uuid,
    short_name,
    average_mark,
    company_name,
    company_ogrn,
    activity_region,
    registered_address,
    supplier_categories,
  } = document ?? {};

  const supplierCardItems: TabsProps["items"] = [
    {
      key: SupplierCardItems.GeneralInfo,
      label: "Общая информация",
      children: (
        <SupplierCardInfo
          inn={inn}
          kpp={kpp}
          ogrn={company_ogrn}
          address={registered_address}
          company={company_name}
          supplierRegions={activity_region}
          supplierCategories={supplier_categories}
        />
      ),
    },
    {
      key: SupplierCardItems.Marks,
      label: "Оценки",
      children: (
        <MarksList supplierUuid={id} />
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getDocData("supplier-counteragent", id));
    }
  }, []);

  useEffect(() => {
    const path: string = window.location.pathname;
    const parts: string[] = path.split("/");

    if (parts.includes(Paths.Marks)) {
      setActiveTabKey(SupplierCardItems.Marks);
    }
  }, []);

  const handleTabChange = (key: string): void => {
    switch (key) {
      case SupplierCardItems.Marks:
        navigate(Paths.Marks);
        break;
      default:
        navigate(Paths.Default);
    }

    setActiveTabKey(key);
  };

  return (
    <div className={css.supplierCardContainer}>
      <SupplierCardHeader
        supplierUuid={id as string}
        supplierName={short_name}
        supplierMark={average_mark}
      />
      <Tabs
        items={supplierCardItems}
        className="mb-4"
        activeKey={activeTabKey}
        onChange={handleTabChange}
      />
    </div>
  );
};

export default SupplierCard;
