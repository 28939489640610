import {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { login } from "../../actions/account.actions";
import { AppStateType } from "../../reducers/mainReducer";
import { CustomButton } from "../CustomButton/CustomButton";
import CustomLoading from "../CustomLoading/CustomLoading";
import { Form, Input } from "antd";
import css from "./LoginForm.module.css";

interface ILoginFormProps {
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
}

const LoginForm: FC<ILoginFormProps> = ({
  setNavBarHidden,
}): JSX.Element => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

  const {
    error,
    authToken,
  } = useSelector((state: AppStateType) => state.account);

  const navigate: NavigateFunction = useNavigate();

  const isDev: boolean = window.location.href.includes("dev");

  const dispatch = useDispatch<AppDispatch>();

  useEffect((): void => {
    if (authToken) {
      setNavBarHidden(false);
      navigate("/suppliers");
    }
  }, [authToken]);

  useEffect((): void => {
    if (!!username && !!password) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [password, username]);

  const handleSubmit = (): void => {
    dispatch(login(username, password));
    setNavBarHidden(false);
  };

  return (
    !authToken ? (
      <div className={css.container}>
        <h1 className={`text-center font-bold mb-3 ${css.header}`}>
          Личный кабинет {isDev && "бухгалтера"}
        </h1>
        <span className={css.underHeader}>
          Пожалуйста, войдите в аккаунт
        </span>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="username"
            validateStatus={error ? "error" : ""}
            hasFeedback
            className="mb-5"
            // rules={[{
            //   required: true,
            //   message: "Пожалуйста, введите ваш логин!"
            // }]}
          >
            <Input
              className="p-2 rounded-lg"
              size="large"
              placeholder="Email или телефон"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            validateStatus={error ? "error" : ""}
            hasFeedback
            className={error ? "mb-0" : "mb-5"}
            // rules={[{
            //   required: true,
            //   message: "Пожалуйста, введите ваш пароль!"
            // }]}
          >
            <Input.Password
              className="p-2 rounded-lg"
              size="large"
              placeholder="Пароль"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            {!!error && <span className="ml-3 text-red-500">Неверный логин или пароль</span>}
          </Form.Item>
          <Form.Item className="mb-5 text-center">
            <CustomButton
              size="large"
              text="Войти"
              type="primary"
              disabled={isButtonDisabled}
              className="w-full"
              htmlType="submit"
            />
            {/*{error && <span className="text-red-500">Неверный логин или пароль</span>}*/}
          </Form.Item>
        </Form>
        {/*TODO временно скрываем, т.к пока не реализована дальнейшая логика*/}
        {/*<span*/}
        {/*  className="block text-center cursor-pointer text-blue-700"*/}
        {/*  onClick={openResetPasswordForm}*/}
        {/*>*/}
        {/*  Не помню пароль*/}
        {/*</span>*/}
      </div>
    ) : (
      <CustomLoading fontSize={24}/>
    )
  );
};

export default LoginForm;
