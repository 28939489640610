import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  DocumentListResultsType,
  DocumentTableParamsType
} from "app/types";
import { getDocsList } from "../../api/document.api";
import { getSupplierInfo, supplierCreate } from "../../api/supplier.api";
import CustomLoading from "../../components/CustomLoading/CustomLoading";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { validateInn } from "./utils/validateInn";
import { supplierCities, supplierCountries, supplierRegions } from "./utils/createSupplierData";
import { Form, message, Spin, Tooltip } from "antd";
import { validateKpp } from "./utils/validateKpp";
import { validateOgrn } from "./utils/validateOgrn";
import { validateActivityRegion } from "./utils/validateActivityRegion";

interface ICreateSupplierProps {
}

enum InnLength {
  Max = 12,
  Min = 10,
}

const isRegion: boolean = true;

const CreateSupplier: FC<ICreateSupplierProps> = (): JSX.Element => {
  const [isSubmittable, setSubmittable] = useState<boolean>(false);
  const [isInfoFetching, setInfoFetching] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [supplierInfo1C, setSupplierInfo1C] = useState<DocumentListResultsType | null>(null);
  const [isSupplierNotFound, setSupplierNotFound] = useState<boolean>(false);
  const [isSupplierHasBeenCreated, setSupplierHasBeenCreated] = useState<boolean>(false);
  const [searchRegion, setSearchRegion] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const navigate: NavigateFunction = useNavigate();

  const isCorrectInnLength: boolean = values?.inn?.length >= InnLength?.Min && values?.inn?.length <= InnLength?.Max;
  const isBtnDisabled: boolean = !isSubmittable || isSupplierHasBeenCreated || isSupplierNotFound || isFormLoading;

  useEffect(() => {
    if (supplierInfo1C) {
      form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    }
  }, [supplierInfo1C, form, values]);

  useEffect(() => {
    if (supplierInfo1C) {
      form.setFieldValue("kpp", supplierInfo1C?.КПП);
      form.setFieldValue("ogrn", supplierInfo1C?.РегистрационныйНомер);
      form.setFieldValue("registered_address", supplierInfo1C?.ИсторияРеквизитов?.Адрес?.[0]?.Представление);
    }
  }, [supplierInfo1C]);

  useEffect(() => {
    if (isSupplierHasBeenCreated || isSupplierNotFound) {
      form.validateFields(["inn"]);
    } else {
      form.setFields([
        {
          name: "inn",
          errors: [],
        },
      ]);
    }
  }, [isSupplierHasBeenCreated, isSupplierNotFound, form]);

  useEffect(() => {
    if (!values?.inn?.length) {
      onInnClear();
    }
  }, [values?.inn]);

  useEffect(() => {
    if (isCorrectInnLength) {
      setInfoFetching(true);

      const supplierListParams: DocumentTableParamsType = {
        search: values?.inn,
      };

      getDocsList("supplier-counteragent", supplierListParams)
        ?.then((response: any): void => setSupplierHasBeenCreated(!!response?.data?.results?.length))
        ?.catch((error: any): void => console.error("Get suppliers list error", error));

      getSupplierInfo(values?.inn)
        ?.then((response: any) => {
            setSupplierInfo1C(response?.data);
            setSupplierNotFound(false);
        })
        ?.catch((error: any) => {
            console.error("Fetch supplier from 1C error", error);
            message.error("Ошибка загрузки поставщика из 1С");
            setSupplierNotFound(true);
        })
        ?.finally(() => setInfoFetching(false));
    }
  }, [isCorrectInnLength, isSupplierHasBeenCreated, values?.inn]);

  const closeForm = (): void => {
    navigate("/suppliers");
  };

  const onFinish = (values: any): void => {
    setIsFormLoading(true);

    const newValues = {
      inn: values?.inn,
      supplier_categories: [...values.supplier_categories],
      activity_region: [...values.activity_region],
      kpp: values?.kpp,
      ogrn: values?.ogrn,
      registered_address: values?.registered_address,
      registered_city: values?.registered_city,
      registered_country: values?.registered_country,
      registered_region: values?.registered_region,
      short_name: supplierInfo1C?.Наименование,
      company_name: supplierInfo1C?.НаименованиеПолное,
    };

    supplierCreate(newValues)
      ?.then(() => {
        //Ставим задержку в полсекунды, чтоб бэк успел обновить данные
        setTimeout(() => {
          closeForm();
          message.success("Поставщик успешно добавлен!");
        }, 2000);
      })
      ?.catch((error: any): void => {
        message.error("Ошибка добавления поставщика");
        console.error("Create supplier mark error", error);
      });
  };

  const onChange = (formItem: string, values: string[]): void => {
    form.setFieldValue(formItem, values);
  };

  const onFormItemClear = (formItem: string): void => {
    form.setFieldValue(formItem, null);
    setSearchRegion("");
    setSearchCategory("");
  };

  const onInnClear = (): void => {
    form.resetFields();
    setSearchRegion("");
    setSearchCategory("");
    onFormItemClear("inn");
    setSupplierInfo1C(null);
    setSupplierNotFound(false);
    setSupplierHasBeenCreated(false);
  };

  return (
    <Spin spinning={isFormLoading}>
      <div className="p-4">
        <h1 className="header mb-4">Добавление поставщика</h1>
        <div>
          <div className="p-5 mb-4 bg-white rounded-xl">
            <h2 className="text-base font-medium mb-5">Основные параметры</h2>
            <Form
              form={form}
              name="markForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="inn"
                label="ИНН"
                validateStatus={isSupplierHasBeenCreated ? "error" : undefined}
                rules={[
                  { required: true },
                  {
                    min: 10,
                    message: "ИНН должен быть меньше 10 символов"
                  },
                  {
                    max: 12,
                    message: "ИНН не может быть больше 12 символов"
                  },
                  { validator: validateInn(isSupplierHasBeenCreated, isSupplierNotFound) }
                ]}
              >
                <CustomInput
                  id="inn"
                  type="default"
                  allowClear
                  placeholder="Введите ИНН"
                  onClear={onInnClear}
                />
              </Form.Item>
              {!!supplierInfo1C && !isInfoFetching && !isSupplierNotFound && !isSupplierHasBeenCreated && (
                <>
                  <div className="flex">
                    <Form.Item
                      name="kpp"
                      label="КПП"
                      className="flex flex-col basis-80 mr-5"
                      rules={[
                        { required: true },
                        {
                          min: 9,
                          message: "КПП должен быть 9 символов"
                        },
                        {
                          max: 9,
                          message: "КПП должен быть 9 символов"
                        },
                        { validator: validateKpp() }
                      ]}
                    >
                      <CustomInput
                        id="kpp"
                        type="default"
                        allowClear
                        placeholder="Введите КПП"
                      />
                    </Form.Item>
                    <Form.Item
                      name="ogrn"
                      label="ОГРН"
                      className="flex flex-col basis-80 mr-5"
                      rules={[
                        { required: true },
                        {
                          min: 13,
                          message: "ОГРН должен быть 13 символов"
                        },
                        {
                          max: 13,
                          message: "ОГРН должен быть 13 символов"
                        },
                        { validator: validateOgrn() }
                      ]}
                    >
                      <CustomInput
                        id="ogrn"
                        type="default"
                        allowClear
                        placeholder="Введите ОГРН"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item
                      name="registered_country"
                      label="Страна регистрации"
                      className="flex flex-col basis-80 mr-5"
                    >
                      {/*<CustomInput*/}
                      {/*  id="registered_country"*/}
                      {/*  type="select"*/}
                      {/*  options={supplierCountries}*/}
                      {/*  allowClear*/}
                      {/*  placeholder="Выберите страну регистрации"*/}
                      {/*  onSelectChange={(values: any) => onChange("registered_country", values)}*/}
                      {/*  onClear={() => onFormItemClear("registered_country")}*/}
                      {/*/>*/}
                      <CustomInput
                        id="registered_country"
                        type="default"
                        allowClear
                        placeholder="Введите страну регистрации"
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_region"
                      label="Регион"
                      className="flex flex-col basis-80 mr-5"
                    >
                      {/*<CustomInput*/}
                      {/*  id="registered_region"*/}
                      {/*  type="select"*/}
                      {/*  options={supplierRegions}*/}
                      {/*  allowClear*/}
                      {/*  placeholder="Выберите регион"*/}
                      {/*  onSelectChange={(values: any) => onChange("registered_region", values)}*/}
                      {/*  onClear={() => onFormItemClear("registered_region")}*/}
                      {/*/>*/}
                      <CustomInput
                        id="registered_region"
                        type="select"
                        allowClear
                        showSearch
                        optionsSchema="region"
                        placeholder="Выберите регион"
                        onSearch={(value: string) => setSearchRegion(value)}
                        onSelectChange={(values: any) => onChange("registered_region", values)}
                        onClear={() => onFormItemClear("registered_region")}
                        docsListParams={searchRegion ? {region_name: searchRegion} : {}}
                        onBlur={() => setSearchRegion("")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_city"
                      label="Населенный пункт"
                      className="flex flex-col basis-80 mr-5"
                    >
                      {/*<CustomInput*/}
                      {/*  id="registered_city"*/}
                      {/*  type="select"*/}
                      {/*  options={supplierCities}*/}
                      {/*  allowClear*/}
                      {/*  placeholder="Выберите населенный пункт"*/}
                      {/*  onSelectChange={(values: any) => onChange("registered_city", values)}*/}
                      {/*  onClear={() => onFormItemClear("registered_city")}*/}
                      {/*/>*/}
                      <CustomInput
                        id="registered_city"
                        type="default"
                        allowClear
                        placeholder="Введите населенный пункт"
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_address"
                      label="Адрес"
                      rules={[{ required: true }]}
                      className="flex flex-col basis-80"
                    >
                      <CustomInput
                        id="registered_address"
                        type="default"
                        allowClear
                        placeholder="Введите адрес"
                        onSelectChange={(values: any) => onChange("registered_address", values)}
                        onClear={() => onFormItemClear("registered_address")}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="activity_region"
                    label="Регионы деятельности"
                    className="flex flex-col basis-80"
                    validateTrigger={["onSelectChange"]}
                    required
                    rules={[{ validator: validateActivityRegion(values?.activity_region, isRegion) }]}
                  >
                    <CustomInput
                      id="activity_region"
                      type="select"
                      mode="multiple"
                      allowClear
                      optionsSchema="region"
                      placeholder="Выберите регионы"
                      onSearch={(value: string) => setSearchRegion(value)}
                      onSelectChange={(values: any) => onChange("activity_region", values)}
                      onClear={() => onFormItemClear("activity_region")}
                      docsListParams={searchRegion ? {region_name: searchRegion} : {}}
                      onBlur={() => setSearchRegion("")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="supplier_categories"
                    label="Категория"
                    className="flex flex-col basis-80"
                    validateTrigger={["onSelectChange"]}
                    required
                    rules={[{ validator: validateActivityRegion(values?.supplier_categories) }]}
                  >
                    <CustomInput
                      id="supplier_categories"
                      type="select"
                      mode="multiple"
                      allowClear
                      optionsSchema="category"
                      placeholder="Выберите категории"
                      onSearch={(value: string) => setSearchCategory(value)}
                      onSelectChange={(values: any) => onChange("supplier_categories", values)}
                      onClear={() => onFormItemClear("supplier_categories")}
                      docsListParams={searchCategory ? {category_name: searchCategory} : {}}
                      onBlur={() => setSearchCategory("")}
                    />
                  </Form.Item>
                </>
              )}
              {isInfoFetching && (
                <div className="flex items-center justify-center h-1/4 bg-[#F7F8F9] rounded-xl">
                  <CustomLoading className="my-20" defaultIcon />
                </div>
              )}
            </Form>
          </div>
          <div className="p-5 bg-white rounded-xl">
            <Form
              form={form}
              name="markForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item noStyle>
                <div className="text-right">
                  <CustomButton
                    className="mr-2"
                    type="default"
                    size="large"
                    text="Отменить"
                    ghost
                    onClick={closeForm}
                  />
                  <Tooltip title={isSupplierHasBeenCreated && "Поставщик уже создан"}>
                    <>
                      <CustomButton
                        type="primary"
                        size="large"
                        text="Подтвердить"
                        htmlType="submit"
                        disabled={isBtnDisabled}
                      />
                    </>
                  </Tooltip>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CreateSupplier;
