import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  DocumentListType,
  SupplierDocumentType,
  DocumentTableParamsType,
  DocumentListResultsType,
} from "app/types";
import {
  tagKeys,
  linkKeys,
  ratingKeys,
  columnWidth,
  settingsItems,
  sortableColumns
} from "./utils/tableData";
import { getDocsList } from "../../../api/document.api";
import { CustomPagination } from "../../../components/CustomPagination/CustomPagination";
import CustomTable from "../../../components/CustomTable/CustomTable";
import SuppliersTableFilters from "./SuppliersTableFilters/SuppliersTableFilters";
import css from "./SuppliersTable.module.css";

interface ISuppliersTableProps {}

const SuppliersTable: FC<ISuppliersTableProps> = (): JSX.Element => {
  const [suppliersDocInfo, setSuppliersDocInfo] = useState<DocumentListType | null>(null);
  const [suppliersList, setSuppliersList] = useState<SupplierDocumentType[]>([]);
  const [tableParams, setTableParams] = useState<DocumentTableParamsType>({ page: 1 });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    getDocsList("supplier-counteragent", tableParams)
      ?.then((response: any): void => {
        const docInfo: DocumentListType = response?.data;
        const supplierDocumentFields: DocumentListResultsType[] =
          docInfo?.results?.flatMap(({ fields }: DocumentListResultsType): DocumentListResultsType => fields);

        setSuppliersDocInfo(docInfo);
        createSuppliersList(supplierDocumentFields);
      })
      ?.catch((error: any): void => console.error("Get suppliers list error", error))
      ?.finally((): void => setIsLoading(false));
  }, [tableParams]);

  const createSuppliersList = (supplierListResponse: SupplierDocumentType[]): void => {
    const supplierDocument: SupplierDocumentType[] = supplierListResponse
    ?.map(({
      uuid,
      inn,
      short_name,
      marks_count,
      average_mark,
      vtb_average_mark,
      central_bank_average_mark,
      supplier_categories,
    }: DocumentListResultsType): DocumentListResultsType => {
      return {
        uuid,
        inn,
        company_name: short_name,
        supplier_categories: supplier_categories?.map(({ category_name }: any): string => category_name as string),
        central_bank_average_mark: central_bank_average_mark?.toFixed(1),
        vtb_average_mark: vtb_average_mark?.toFixed(1),
        average_mark: (average_mark as number)?.toFixed(1),
        marks_count
      };
    });

    setSuppliersList(supplierDocument);
  };

  const handleSort = (sortField: string): void => {
    setTableParams({
      ...tableParams,
      order_by: sortField,
    });
  };

  const handlePageChange = (currentPage: number): void => {
    setTableParams({
      ...tableParams,
      page: currentPage,
    });
  };

  const handlePageSizeChange = (pageSize: number): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      page_size: pageSize,
    });
  };

  return (
    <div className={css.suppliersTableContainer}>
      <SuppliersTableFilters
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
      <CustomTable
        data={suppliersList}
        linkTo="supplier"
        tagKeys={tagKeys}
        linkKeys={linkKeys}
        isLoading={isLoading}
        ratingKeys={ratingKeys}
        columnWidth={columnWidth}
        settingsItems={settingsItems}
        sortableColumns={sortableColumns}
        sortableFunction={handleSort}
        hasSettings
      />
      <CustomPagination
        total={suppliersDocInfo?.total_docs as number}
        pageSize={suppliersDocInfo?.page_size as number}
        currentPage={suppliersDocInfo?.page as number}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default SuppliersTable;
