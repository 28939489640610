import { combineReducers } from "redux";
import account from "./account.reducer";
import document from "./document.reducer";
import notifications from "./notifications.reducer";

const mainReducer = combineReducers({
  account,
  document,
  notifications,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
