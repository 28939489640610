export const supplierCardHeaderOptions = [
  {
    label: "Добавить новую оценку",
    value: "mark-form"
  },
  {
    label: "Получить ссылку для добавления оценки",
    value: "link-form"
  },
];

export const customSelectStyle = {
  components: {
    Select: {
      fontSizeLG: 15,
      selectorBg: "#3438E0",
      colorIcon: "white",
      controlOutlineWidth: 0,
      colorTextPlaceholder: "white",
      colorBgContainerDisabled: "#67768C26"
    }
  }
};
