import {
  FC,
  JSX,
  ReactNode,
} from "react";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/CustomTable/settings-icon.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  MenuProps,
} from "antd";

interface TableSettingsProps {
  menuItems?: MenuProps["items"];
  record: any;
}

enum MenuItems {
  CreateMark = "1",
  SupplierLink = "2",
}

const SettingsColumn: FC<TableSettingsProps> = ({ menuItems, record }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const handleMenuClick: MenuProps["onClick"] = (event) => {
    switch (event.key) {
      case MenuItems.CreateMark:
        return navigate(`/mark-form/${record.uuid}`);
      case MenuItems.SupplierLink:
        return navigate(`supplier-link/${record.uuid}`);
      default:
        return "";
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown.Button
      menu={menuProps}
      buttonsRender={([_leftButton, _rightButton]: ReactNode[]) => [
        null,
        <Button
          key="settings"
          type="text"
          icon={<SettingsIcon />}
        />
      ]}
    />
  );
};

export default SettingsColumn;
