import {
  FC,
  JSX,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/CustomButton/CustomButton";

interface ISuppliersHeaderProps {}

const SuppliersHeader: FC<ISuppliersHeaderProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const openMarkForm = (): void => {
    navigate("/mark-form");
  };

  const openCreateSupplierForm = (): void => {
    navigate("/create-supplier");
  };

  return (
    <div className="flex items-center justify-between mb-4">
      <h1 className="header">
        Поставщики
      </h1>
      <div>
        <CustomButton
          type="default"
          size="large"
          text="Добавить поставщика"
          className="mr-2"
          onClick={openCreateSupplierForm}
        />
        <CustomButton
          type="primary"
          size="large"
          text="Добавить оценку"
          onClick={openMarkForm}
        />
      </div>
    </div>
  );
};

export default SuppliersHeader;
