import axios from "../utils/axios";
import { AssessingLinkType, NotificationsParamsType } from "app/types";

const API_NOTIFICATIONS: string | undefined = process.env.REACT_APP_API_TASKS_URL?.length
  ? process.env.REACT_APP_API_TASKS_URL
  : "https://dev.pipeline.smbconnect.ru";

const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_NOTIFICATIONS;

//ручка получения уведомлений
export const getNotifications = async (params: NotificationsParamsType | null = null): Promise<any> => {
  return axios.GET(url, "api/mdm/v1/notifications", params);
};

//ручка прочтения уведомления
export const notificationRead = async (uuid: string): Promise<any> => {
  return axios.POST(url, `api/mdm/v1/notifications/${uuid}/read`);
};

//ручка прочтения всех уведомлений
export const allNotificationsRead = async (): Promise<any> => {
  return axios.POST(url, "api/mdm/v1/notifications/mark_all_read");
};

//ручка получения данных по кол-ву уведомлений (МОКОВАЯ, ожидаем бэк!!!)
export const getFacetsOfNotifications = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/v1/notifications/facet");
};

//ручка отправки ссылки на оценку поставщика
export const sendAssessingLink = async (body: AssessingLinkType): Promise<any> => {
  return axios.POST(url, "api/mdm/v1/notifications/send", body);
};
