import { ICustomInputProps } from "../../../../../components/CustomInput/CustomInput";

export const tableFiltersData: ICustomInputProps[] = [
  {
    id: "supplier_categories.category_name",
    type: "select",
    className: "basis-1/5 mr-2",
    placeholder: "Найти по категории",
    marksPlaceholder: "Найти по категории",
    optionsSchema: "category",
  },
  {
    id: "activity_region.region_name",
    type: "select",
    className: "basis-1/5 mr-2",
    placeholder: "Найти по региону деятельности",
    marksPlaceholder: "Найти по региону деятельности",
    optionsSchema: "region",
  },
  {
    id: "search",
    type: "default",
    isSearch: true,
    className: "basis-3/5",
    placeholder: "Найти по ОГРН, ИНН, КПП или наименованию контрагента",
    marksPlaceholder: "Поиск",
  },
];
