import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDocData } from "../../actions/documents.actions";
import SuppliersHeader from "./SuppliersHeader/SuppliersHeader";
import SuppliersTable from "./SuppliersTable/SuppliersTable";
import AssessingSupplierLink from "../../components/AssessingSupplierLink/AssessingSupplierLink";
import css from "./Suppliers.module.css";

interface ISuppliersProps {}

const SuppliersList: FC<ISuppliersProps> = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const location: string = useLocation()?.pathname;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const splittedPath: string[] = location?.split("/");
    const hasSupplierLink: boolean = splittedPath?.includes("supplier-link");

    if (hasSupplierLink) {
      const supplierLink: string = splittedPath?.slice(-1)?.toString();

      setIsModalOpen(true);
      dispatch(getDocData("supplier-counteragent", supplierLink));
    }
  }, [location]);

  return (
    <div className={css.suppliersContainer}>
      <SuppliersHeader />
      <SuppliersTable />
      <AssessingSupplierLink
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default SuppliersList;
