import axios from "../utils/axios";
import { DocumentTableParamsType } from "app/types";

const API_DOCUMENT: string | undefined = process.env.REACT_APP_API_TASKS_URL?.length
  ? process.env.REACT_APP_API_TASKS_URL
  : "https://dev.pipeline.smbconnect.ru";

// Ручка получения оценки
export const getMarkInfo = async (supplierId: string, params?: DocumentTableParamsType): Promise<any> => {
  return axios.GET(API_DOCUMENT, `api/mdm/marks/v1/supplier/${supplierId}`, params);
};
