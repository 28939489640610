import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  MarkType,
  DocumentType,
  DocumentListType,
  DocumentTableParamsType,
  DocumentListResultsType,
  ContractorCriteriaType,
  RelationDocumentType,
} from "app/types";
import { getDocsList } from "../../api/document.api";
import { columnWidth, ratingKeys, sortableColumns, subheadKeys } from "./utils/tableData";
import CustomTable from "../../components/CustomTable/CustomTable";
import { CustomPagination } from "../../components/CustomPagination/CustomPagination";
import SuppliersTableFilters from "../SuppliersList/SuppliersTable/SuppliersTableFilters/SuppliersTableFilters";
import { getMarkInfo } from "../../api/marks.api";
import { formatAuthorName } from "./utils/formatAuthorName";

interface IMarksListProps {
  supplierUuid?: string;
}

interface ProcessedMark {
  uuid: string;
  short_name: string;
  [key: string]: string | number;
}

const MarksList: FC<IMarksListProps> = ({ supplierUuid }): JSX.Element => {
  const [markDocInfo, setMarkDocInfo] = useState<DocumentListType | null>(null);
  const [contractorMarkList, setContractorMarkList] = useState<MarkType[]>([]);
  const [tableParams, setTableParams] = useState<DocumentTableParamsType>({
    page: 1,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    getMarkInfo(supplierUuid as string, tableParams)
      ?.then((response) => {
        const docInfo: DocumentListType = response?.data;
        const markListDocumentFields: MarkType[] = response?.data?.marks;

        setMarkDocInfo(docInfo);
        createContractorMarkListList(markListDocumentFields);
      })
      ?.catch((error: any): void => console.error("Get marks list error", error))
      ?.finally((): void => setIsLoading(false));
  }, [tableParams]);

  const createContractorMarkListList = (marksList: MarkType[]): void => {
    const markDocument: ProcessedMark[] = marksList?.map((
      {
        uuid,
        author,
        short_name,
        deadline_criteria,
        expectation_criteria,
        communication_criteria,
        material_security_criteria,
      }: MarkType): ProcessedMark => {
      return {
        uuid,
        short_name: `${formatAuthorName(String(author))}/${short_name}`,
        deadline_criteria: deadline_criteria?.criteria_points ?? 0,
        expectation_criteria: expectation_criteria?.criteria_points ?? 0,
        communication_criteria: communication_criteria?.criteria_points ?? 0,
        material_security_criteria: material_security_criteria?.criteria_points ?? 0,
      };
    });

    setContractorMarkList(markDocument);
  };

  // Старая логика получения данных оценки с пагинацией и сортировкой
  // useEffect(() => {
  //   setIsLoading(true);
  //
  //   getDocsList("contractor-mark", tableParams)
  //   ?.then((response: any): void => {
  //     const docInfo: DocumentListType = response?.data;
  //     const markListDocumentFields: MarkType[] =
  //       response?.data?.results?.flatMap(({ fields }: DocumentListResultsType): DocumentListResultsType => fields);
  //
  //     setMarkDocInfo(docInfo);
  //     createContractorMarkListList(markListDocumentFields);
  //   })
  //   ?.catch((error: any): void => console.error("Get marks list error", error))
  //   ?.finally((): void => setIsLoading(false));
  // }, [tableParams]);

  // const createContractorMarkListList = (marksList: MarkType[]): void => {
  //   const markDocument: ProcessedMark[] = marksList
  //   ?.map(({
  //     uuid,
  //     author,
  //     short_name,
  //     contractor_mark_criteria,
  //   }: MarkType): ProcessedMark => {
  //     const result: ProcessedMark = {
  //       uuid,
  //       short_name: `${author?.[0]?.label}/${short_name}`,
  //     };
  //
  //     contractor_mark_criteria?.forEach((criteria: RelationDocumentType) => {
  //       criteria?.contractor_criteria.forEach((contractor_criteria: ContractorCriteriaType) => {
  //         const { slug } = contractor_criteria;
  //         const { mark_criteria_points } = criteria;
  //
  //         result[slug] = mark_criteria_points;
  //       });
  //     });
  //
  //     return result;
  //   });
  //
  //   setContractorMarkList(markDocument);
  // };

  const handleSort = (sortField: string): void => {
    setTableParams({
      ...tableParams,
      order_by: sortField,
    });
  };

  const handlePageChange = (currentPage: number): void => {
    setTableParams({
      ...tableParams,
      page: currentPage,
    });
  };

  const handlePageSizeChange = (pageSize: number): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      page_size: pageSize,
    });
  };

  return (
    <div className="p-4 bg-white rounded-xl">
      <SuppliersTableFilters tableParams={tableParams} setTableParams={setTableParams} isMarkList />
      <CustomTable
        data={contractorMarkList}
        linkTo={`supplier/${supplierUuid}/marks`}
        isLoading={isLoading}
        subheadKeys={subheadKeys}
        columnWidth={columnWidth}
        ratingKeys={ratingKeys}
        sortableColumns={sortableColumns}
        sortableFunction={handleSort}
      />
      <CustomPagination
        total={markDocInfo?.total_docs ?? 0}
        pageSize={markDocInfo?.page_size ?? 20}
        currentPage={markDocInfo?.page ?? 1}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default MarksList;
