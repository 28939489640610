import axios from "../utils/axios";
import { AxiosResponse } from "axios";
import {
  DocumentListType,
  DocumentTableParamsType,
  DocumentListResultsType,
} from "app/types";

const API_DOCUMENT: string | undefined = process.env.REACT_APP_API_TASKS_URL?.length
  ? process.env.REACT_APP_API_TASKS_URL
  : "https://dev.pipeline.smbconnect.ru";

//ручка получения списка документов
export const getDocsList = async (
  schemaName: string,
  params?: DocumentTableParamsType
): Promise<AxiosResponse<DocumentListType>> => {
  return axios.GET(API_DOCUMENT, `api/mdm/documents/v1/d/${schemaName}`, params);
};

//ручка получения документа
export const getDocument = async (
  schemaName: string,
  docUuid: string
): Promise<AxiosResponse<DocumentListResultsType>> => {
  return axios.GET(API_DOCUMENT, `api/mdm/documents/v1/d/${schemaName}/uuid/${docUuid}`);
};

//ручка получения списка схем
export const getSchemas = async (
  params: DocumentTableParamsType = { page_size: 100 }
): Promise<AxiosResponse<DocumentListType>> => {
  return axios.GET(API_DOCUMENT, "api/mdm/documents/v1/schemas", params);
};

//ручка получения схемы документа
export const getDocSchema = async (schemaName: string): Promise<AxiosResponse<DocumentListResultsType>> => {
  return axios.GET(API_DOCUMENT, `api/mdm/documents/v1/schemas/${schemaName}`);
};
