import {
  FC,
  JSX,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { AssessingLinkType, DocumentListResultsType } from "app/types";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { getDocumentFailure } from "../../actions/documents.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { CustomInput } from "../CustomInput/CustomInput";
import CustomModal from "../CustomModal/CustomModal";
import { message } from "antd";
import { sendAssessingLink } from "../../api/notifications.api";

interface IAssessingSupplierLinkProps {
  isCard?: boolean;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const host: string = window.location.host;

const AssessingSupplierLink: FC<IAssessingSupplierLinkProps> = ({
  isCard,
  isModalOpen,
  setIsModalOpen,
}): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState<string | JSX.Element>("");
  const [isLinkSubmitting, setIsLinkSubmitting] = useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const document: DocumentListResultsType | null = useSelector((state: AppStateType) => state.document.document);

  const {
    uuid,
    short_name,
  } = document ?? {};

  const supplierMarkFormLink: string = `${host}/mark-form/${uuid}/${isCard ? "card" : ""}`;

  const shortenLink = (link: string): string => {
    let url;

    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      url = new URL(`http://${link}`);
    } else {
      url = new URL(link);
    }
    return `${url.host}...`;
  };

  const handleCloseModal = (): void => {
    navigate(-1);
    setSelectedUser("");
    setIsModalOpen(false);
    setIsModalOpen(false);
    setIsLinkSubmitting(false);
    !isCard && dispatch(getDocumentFailure());
  };

  const handleApproveModal = (): void => {
    setIsLinkSubmitting(true);

    const linkData: AssessingLinkType = {
      recipient: selectedUser as string,
      strategy: "invite_contractor_to_make_mark",
      context: {
        company_name: short_name,
        company_url: supplierMarkFormLink,
      },
    };

    sendAssessingLink(linkData)
      ?.then(() => {
        handleCloseModal();
        message.success("Ссылка успешно отправлена");
      })
      ?.catch((error) => {
        console.error("Send link error", error);
        message.error("Ошибка отправки ссылки");
      })
      ?.finally(() => setIsLinkSubmitting(false));
  };

  const copyToClipboardFallback = (text: string): void => {
    const textArea = window.document.createElement("textarea");
    textArea.value = text;
    window.document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = window.document.execCommand("copy");
      if (successful) {
        message.success("Ссылка скопирована в буфер обмена!");
      } else {
        message.error("Не удалось скопировать текст.");
      }
    } catch (err) {
      console.error("Ошибка при копировании текста: ", err);
      message.error("Не удалось скопировать текст.");
    } finally {
      window.document.body.removeChild(textArea);
    }
  };

  const handleCopyClick = async (): Promise<void> => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(supplierMarkFormLink);
        message.success("Ссылка скопирована в буфер обмена!");
      } catch (err) {
        console.error("Ошибка при копировании текста: ", err);
        message.error("Не удалось скопировать текст.");
      }
    } else {
      copyToClipboardFallback(supplierMarkFormLink);
    }
  };

  const onSelect = (values: string | JSX.Element): void => {
    setSelectedUser(values);
  };

  const onClear = (): void => {
    setSelectedUser("");
  };

  const renderModalLabel = (label: string): JSX.Element => (
    <span className="mb-1 text-sm text-gray-500">{label}</span>
  );

  return (
    <CustomModal
      modalTitle="Ссылка для оценки поставщика"
      cancelText="Закрыть"
      okText="Отправить"
      isModalOpen={isModalOpen}
      handleCancel={handleCloseModal}
      handleOk={handleApproveModal}
      cancelButtonProps={{ size: "large" }}
      okButtonProps={{
        size: "large",
        disabled: !selectedUser || isLinkSubmitting,
    }}
    >
      <>
        <div className="mb-5">
          <span className="block mb-1">Кому отправить</span>
          <CustomInput
            id="user"
            type="select"
            allowClear
            isEmail
            onSelect={onSelect}
            className="w-full"
            optionsSchema="allUsers"
            placeholder="Выберите пользователя"
            onClear={onClear}
            selectValue={selectedUser}
          />
        </div>
        <div className="flex mb-5">
          <div className="flex flex-col w-1/2">
            {renderModalLabel("Контрагент")}
            {short_name}
          </div>
          <div className="flex flex-col">
            {renderModalLabel("Ссылка")}
            <div className="flex">
              {shortenLink(supplierMarkFormLink)}
              <CopyIcon className="ml-2 cursor-pointer" onClick={handleCopyClick} />
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
};

export default AssessingSupplierLink;
