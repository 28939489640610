import { FC, JSX } from "react";
import { useLocation } from "react-router-dom";
import { getDocumentFailure } from "../../../actions/documents.actions";
import ReturnButton from "../../../components/ReturnButton/ReturnButton";

interface IMarkCardHeaderProps {
  short_name?: string;
}

const MarkCardHeader: FC<IMarkCardHeaderProps> = ({ short_name }): JSX.Element => {
  const location = useLocation();

  const extractBasePath = (pathname: string): string => {
    const pathParts: string[] = pathname.split("/");

    return pathParts.slice(0, 4).join("/") + "/";
  };

  return (
    <div className="flex">
      <ReturnButton linkTo={extractBasePath(location.pathname)} action={getDocumentFailure} />
      <h1 className="header mb-4">Оценка {short_name && `- ${short_name}`}</h1>
    </div>
  );
};

export default MarkCardHeader;
