import {
  FC,
  JSX,
} from "react";
import Tag from "../../../components/Tag/Tag";
import ListItem from "../../../components/ListItem/ListItem";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { RelationDocumentType } from "app/types";

interface ISupplierCardInfoProps {
  inn?: string;
  kpp?: string;
  ogrn?: string;
  address?: string;
  company?: string;
  supplierRegions?: RelationDocumentType[];
  supplierCategories?: RelationDocumentType[];
}

const SupplierCardInfo: FC<ISupplierCardInfoProps> = ({
  inn,
  kpp,
  ogrn,
  address,
  company,
  supplierRegions = [],
  supplierCategories = [],
}): JSX.Element => {
  const listItems = [
    { label: "Организация", value: company },
    { label: "ИНН", value: inn },
    { label: "КПП", value: kpp },
    { label: "ОГРН", value: ogrn },
    { label: "Адрес", value: address },
  ];

  const renderTags = (
    items: RelationDocumentType[],
    labelKey: keyof RelationDocumentType,
    colorKey?: keyof RelationDocumentType,
  ): JSX.Element[] => (
    items.map((item: RelationDocumentType): JSX.Element => (
      <Tag
        key={item[labelKey] as string}
        tag={item[labelKey] as string}
        color={colorKey ? item[colorKey] as string : undefined}
        className="mr-2 mb-2"
      />
    ))
  );

  const renderSupplierTagsBlock = (
    blockHeader: string,
    blockTags: JSX.Element[],
  ): false | JSX.Element => (
    !!blockTags?.length && (
      <div className="mb-4 p-5 bg-white rounded-xl">
        <div className="flex items-center justify-between">
          <h2 className="sub-header mb-5">
            {blockHeader}
          </h2>
          {/*<CustomButton*/}
          {/*  type="link"*/}
          {/*  size="large"*/}
          {/*  text="Изменить"*/}
          {/*  //TODO: Реализовать на клик открытие модалки категорий и регионов, как будет готов дизайн*/}
          {/*  //onClick={() => {}}*/}
          {/*/>*/}
        </div>
        <div className="flex items-center flex-wrap">
          {blockTags}
        </div>
      </div>
    )
  );

  return (
    <div>
      {renderSupplierTagsBlock("Категории", renderTags(supplierCategories, "category_name", "color"))}
      {renderSupplierTagsBlock("Регионы", renderTags(supplierRegions, "region_name"))}
      <div className="mb-4 p-5 bg-white rounded-xl">
        <h2 className="sub-header mb-5">О поставщике</h2>
        <ul>
          {listItems?.map(({ label, value }): JSX.Element => (
            <ListItem key={label} label={label} value={value} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SupplierCardInfo;
