import {
  FC,
  JSX,
} from "react";
import { ReactComponent as StarIcon } from "../../../../assets/icons/CustomTable/star.svg";
import css from "./RatingColumn.module.css";

interface TableSettingsProps {
  rating?: number;
}

const RatingColumn: FC<TableSettingsProps> = ({ rating }): JSX.Element => {
  return (
    <div className={css.ratingContainer}>
      {rating && (
        <>
          <StarIcon className="mr-2" /> <span>{rating}</span>
        </>
      )}
    </div>
  );
};

export default RatingColumn;
