import {
  FC,
  JSX,
  CSSProperties,
} from "react";
import Tag from "../../../Tag/Tag";

interface ITagsColumnProps {
  tags: string[];
  style?: CSSProperties;
  className?: string;
}

const TagsColumn: FC<ITagsColumnProps> = ({
  tags,
  style,
  className,
}): JSX.Element => {
  const renderTags: JSX.Element[] = tags?.map((tag: string): JSX.Element => {
    return (
      <Tag
        key={tag}
        tag={tag}
        style={style}
        isTableTag
        className={`${className} mr-2.5`}
      />
    );
  });

  return (
    <>
      {renderTags}
    </>
  );
};

export default TagsColumn;
