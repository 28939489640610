// Account actions
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";
export const GET_USER_FULL_INFO_SUCCESS = "GET_USER_FULL_INFO_SUCCESS";
export const GET_USER_FULL_INFO_FAILURE = "GET_USER_FULL_INFO_FAILURE";
export const UPDATE_USER_FULL_INFO_SUCCESS = "UPDATE_USER_FULL_INFO_SUCCESS";
export const UPDATE_USER_FULL_INFO_FAILURE = "UPDATE_USER_FULL_INFO_FAILURE";
export const GET_USER_ROLE_LIST_SUCCESS = "GET_USER_ROLE_LIST_SUCCESS";
export const GET_USER_ROLE_LIST_FAILURE = "GET_USER_ROLE_LIST_FAILURE";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_FAILURE = "GET_USER_ROLE_FAILURE";
export const GET_USER_TOKEN_DECRYPTION_SUCCESS = "GET_USER_TOKEN_DECRYPTION_SUCCESS";
export const GET_USER_TOKEN_DECRYPTION_FAILURE = "GET_USER_TOKEN_DECRYPTION_FAILURE";
export const GET_USERS_LISTING_SUCCESS = "GET_USERS_LISTING_SUCCESS";
export const GET_USERS_LISTING_FAILURE = "GET_USERS_LISTING_FAILURE";
export const DECODE_TOKEN = "DECODE_TOKEN";
export const GET_PERFORMERS_LISTING_SUCCESS = "GET_PERFORMERS_LISTING_SUCCESS";
export const GET_PERFORMERS_LISTING_FAILURE = "GET_PERFORMERS_LISTING_FAILURE";
export const GET_CLIENTS_SERVED_SUCCESS = "GET_CLIENTS_SERVED_SUCCESS";
export const GET_CLIENTS_SERVED_FAILURE = "GET_CLIENTS_SERVED_FAILURE";
export const GET_CLIENTS_SERVED_PAGE_SIZE = "GET_CLIENTS_SERVED_PAGE_SIZE";
export const GET_CLIENTS_SERVED_PAGE_NUMBER = "GET_CLIENTS_SERVED_PAGE_NUMBER";

// Documents actions
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_FAILURE = "GET_DOCUMENT_LIST_FAILURE";
export const GET_DOCUMENT_SCHEMA_SUCCESS = "GET_DOCUMENT_SCHEMA_SUCCESS";
export const GET_DOCUMENT_SCHEMA_FAILURE = "GET_DOCUMENT_SCHEMA_FAILURE";
export const GET_DOCUMENT_SCHEMA_LIST_SUCCESS = "GET_DOCUMENT_SCHEMA_LIST_SUCCESS";
export const GET_DOCUMENT_SCHEMA_LIST_FAILURE = "GET_DOCUMENT_SCHEMA_LIST_FAILURE";

// Notifications actions
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAILURE = "READ_NOTIFICATIONS_FAILURE";
export const GET_NOTIFICATIONS_NUMBER_SUCCESS = "GET_NOTIFICATIONS_NUMBER_SUCCESS";
export const GET_NOTIFICATIONS_NUMBER_FAILURE = "GET_NOTIFICATIONS_NUMBER_FAILURE";
