import { FC, JSX } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/ReturnButton/arrow_left_icon.svg";
import { CustomButton } from "../CustomButton/CustomButton";
import css from "./ReturnButton.module.css";

interface IReturnButtonProps {
  linkTo: string;
  action: any;
}

const ReturnButton: FC<IReturnButtonProps> = ({ linkTo, action }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const returnBack = (): void => {
    navigate(`${linkTo}`);
    dispatch(action());
  };

  return (
    <CustomButton
      className={css.buttonReturn}
      type="link"
      text=""
      onClick={returnBack}
    >
      <ArrowLeft />
    </CustomButton>
  );
};

export default ReturnButton;
