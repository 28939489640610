import {
  FC,
  JSX,
  useEffect,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import css from "./StubPage.module.css";

interface IStubPageProps {}

const StubPage: FC<IStubPageProps> = (): JSX.Element => {

  const navigate: NavigateFunction = useNavigate();

  useEffect((): void => {
    navigate("/login");
  }, []);

  return (
    <div className={css.bodyStyle}></div>
  );
};

export default StubPage;
