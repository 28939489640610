import {
  FC,
  JSX,
} from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface ICustomLoadingProps {
  className?: string;
  fontSize?: number;
  defaultIcon?: boolean;
}

const CustomLoading: FC<ICustomLoadingProps> = ({
  className = "",
  fontSize = 40,
  defaultIcon,
}): JSX.Element => {

  const antIcon: JSX.Element = <LoadingOutlined style={{ fontSize: fontSize }} spin />;

  return (
    <Spin indicator={!defaultIcon ? antIcon : undefined} className={className}/>
  );
};

export default CustomLoading;
