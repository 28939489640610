import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { getMarkInfo } from "../../api/marks.api";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { getDocData } from "../../actions/documents.actions";
import { AppStateType } from "../../reducers/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { DocumentListResultsType, MarksListType } from "app/types";
import MarkCardHeader from "./MarkCardHeader/MarkCardHeader";
import MarkCardInfo from "./MarkCardInfo/MarkCardInfo";
import css from "./MarkCard.module.css";

interface IMarkCardProps {}

const MarkCard: FC<IMarkCardProps> = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const document: DocumentListResultsType | null = useSelector((state: AppStateType) => state.document.document);

  const {
    author,
    comment,
    short_name,
    activity_region,
    supplier_categories,
    contractor_mark_criteria
  } = document ?? {};

  const criteriaPoints: Record<string, number> = contractor_mark_criteria?.reduce(
    (acc: Record<string, number>, item: any) => {
      item?.contractor_criteria?.forEach((criteria: any) => {
        acc[criteria?.slug] = item?.mark_criteria_points;
      });
      return acc;
    },
    {}
  );

  useEffect(() => {
    if (id) {
      dispatch(getDocData("contractor-mark", id));
    }
  }, [id]);

  return (
    <div className={css.markCardContainer}>
      <MarkCardHeader short_name={short_name} />
      <MarkCardInfo
        author={author}
        comment={comment}
        deadline_criteria={criteriaPoints?.deadline_criteria}
        expectation_criteria={criteriaPoints?.expectation_criteria}
        communication_criteria={criteriaPoints?.communication_criteria}
        material_security_criteria={criteriaPoints?.material_security_criteria}
        activityRegion={activity_region}
        supplierCategories={supplier_categories}
      />
    </div>
  );
};

export default MarkCard;
