import {
  FC,
  JSX,
  useState,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from "react";
import { DocumentTableParamsType } from "app/types";
import { CustomInput, ICustomInputProps } from "../../../../components/CustomInput/CustomInput";
import { tableFiltersData } from "./utils/tableFiltersData";
import css from "./SuppliersTableFilters.module.css";

interface ISuppliersTableFiltersProps {
  isMarkList?: boolean;
  tableParams: DocumentTableParamsType;
  setTableParams: Dispatch<SetStateAction<DocumentTableParamsType >>;
}

const SuppliersTableFilters: FC<ISuppliersTableFiltersProps> = ({
  isMarkList,
  tableParams,
  setTableParams,
}): JSX.Element => {
  const [searchCategory, setSearchCategory] = useState<string>("");

  const handleInputChange = (inputKey: string, event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      [inputKey]: event.target.value?.length ? event.target.value : null,
    });
  };

  const handleSelect = (inputKey: string, event: string): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      [inputKey]: event
    });
  };

  const clearFilterParams = (inputKey: string): void => {
    const key: keyof DocumentTableParamsType = inputKey as keyof DocumentTableParamsType;
    const { [key]: _, ...newTableParams }: DocumentTableParamsType = tableParams;

    setTableParams(newTableParams);
  };
  
  const renderTableFilter = (): JSX.Element[] => tableFiltersData?.map(({
    id,
    type,
    isSearch,
    className,
    placeholder,
    optionsSchema,
    marksPlaceholder,
  }: ICustomInputProps): JSX.Element => {
    const key: keyof DocumentTableParamsType = id as keyof DocumentTableParamsType;
    const docParamKey: string = optionsSchema === "region" ? "region_name" : "category_name";

    return (
      <CustomInput
        id={id}
        key={id}
        type={type}
        value={tableParams[key] ? String(tableParams[key]) : undefined}
        isSearch={isSearch}
        className={className}
        allowClear
        showSearch
        searchValue={tableParams[key] ? String(tableParams[key]) : undefined}
        placeholder={isMarkList ? marksPlaceholder : placeholder}
        optionsSchema={optionsSchema}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => handleInputChange(id, event)}
        onSelect={(event: string | JSX.Element): void => handleSelect(id, event as string)}
        onClear={(): void => clearFilterParams(id)}
        onSearch={(value: string) => setSearchCategory(value)}
        docsListParams={searchCategory ? {[docParamKey]: searchCategory} : {}}
        onBlur={() => setSearchCategory("")}
      />
    );
  });

  return (
    <div className={css.filterContainer}>
      {renderTableFilter()}
    </div>
  );
};

export default SuppliersTableFilters;
