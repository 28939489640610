import {
  FC,
  JSX,
} from "react";

interface IRenderLabelProps {
  label: string;
  isRate?: boolean;
}

const RenderLabel: FC<IRenderLabelProps> = ({ label, isRate }): JSX.Element => (
  <span className={`p-0 ${isRate && "font-medium"}`}>{label}</span>
);

export default RenderLabel;
