import {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
  ReactNode,
  SetStateAction,
} from "react";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { logout } from "../../actions/account.actions";
import { ReactComponent as NavbarIcon } from "../../assets/icons/NavBar/navbar_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/NavBar/logout.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/NavBar/profile.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/NavBar/arrow_left.svg";
import { ReactComponent as SuppliersIcon } from "../../assets/icons/NavBar/users_group_icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/NavBar/arrow_right.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/Notification/notification.svg";
import Notifications from "../Notifications/Notifications";
import NotificationsCounter from "../Notifications/NotificationsCounter/NotificationsCounter";
import Sider from "antd/es/layout/Sider";
import { Button, Menu } from "antd";
import type { MenuProps } from "antd";
import css from "./NavBar.module.css";

type MenuItem = Required<MenuProps>["items"][number];

interface INavBarProps {
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const NavBar: FC<INavBarProps> = ({
  setNavBarHidden,
  collapsed,
  setCollapsed
}): JSX.Element => {
  const pathname: string = useLocation()?.pathname;

  const [selectedItem, setSelectedItem] = useState<string[]>([pathname?.split("/")[1]]);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    selectCurrentItem();
  }, [pathname]);

  useEffect(() => {
    notificationVisible
      ? setSelectedItem(["notifications"])
      : selectCurrentItem();
  }, [notificationVisible]);

  const setActiveMenuItem = (itemName: string): void => {
    if (pathname?.split("/")?.includes(itemName)) setSelectedItem([itemName]);
  };

  const selectCurrentItem = () => {
    const pathWords: string[] = pathname?.split("/") || [];
    const isSupplierCard: boolean = pathWords.includes("supplier");

    if (isSupplierCard) {
      setSelectedItem(["suppliers"]);
    }

    setActiveMenuItem("suppliers");
  };

  const getMenuItem = (
    label: ReactNode,
    key: Key,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  };

  const logOut = (): void => {
    setNavBarHidden(true);
    dispatch(logout());
  };

  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = ({ key }: { key: string }): void => {
    if (key === "notifications") {
      setNotificationVisible(prev => !prev);
    } else if (key !== "logout") {
      navigate(key);
      setNotificationVisible(false);
    } else {
      logOut();
      navigate("/login");
      setNotificationVisible(false);
    }
    setSelectedItem([key]);
  };

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const IconComponent = (): JSX.Element => {
    if (isHovering) {
      return collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />;
    }
    return <NavbarIcon />;
  };

  const publicItems: MenuItem[] = [
    getMenuItem(
      <Link
        to={"/suppliers"}
        className={css.menuText}
      >
        Поставщики
      </Link>,
      "suppliers",
      <SuppliersIcon className="flex-shrink-0"/>
    ),
  ];

  const personalItems: MenuItem[] = [
    //TODO: В бета версии проекта эти разделы не реализуем
    // getMenuItem(
    //   <span className={css.menuText}>
    //     Уведомления
    //   </span>,
    //   "notifications",
    //   selectedItem[0] === "notifications" ? (
    //     <div>
    //       <NotificationIcon className="flex-shrink-0"/>
    //       <NotificationsCounter collapsed={collapsed}/>
    //     </div>
    //   ) : (
    //     <div>
    //       <NotificationIcon className="flex-shrink-0"/>
    //       <NotificationsCounter collapsed={collapsed}/>
    //     </div>
    //   )
    // ),
    // getMenuItem(
    //   <Link
    //     to={"/profile"}
    //     className={css.menuText}
    //   >
    //     Мой профиль
    //   </Link>,
    //   "profile",
    //   <ProfileIcon className="flex-shrink-0"/>
    // ),
    getMenuItem(
      <span
        className={css.menuText}
        onClick={() => logOut()}
      >
        Выйти
      </span>,
      "logout",
      <LogoutIcon className="flex-shrink-0"/>
    )
  ];

  return (
    <div className="flex">
      <Sider
        style={{
          height: "100vh",
          backgroundColor: "#101928",
          ...(collapsed ? {} : { paddingLeft: "12px", paddingRight: "12px" })
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={68}
      >
        <Menu
          mode="inline"
          theme="dark"
          className={`${css.menuHeight} mt-2`}
          selectedKeys={selectedItem}
          onClick={handleMenuItemClick}
          items={publicItems}
        />
        <Button
          className={`${css.menuCollapseBtn} absolute right-0 top-1/2 z-10`}
          type="text"
          onClick={toggleCollapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {IconComponent()}
        </Button>
        <Menu
          mode="inline"
          theme="dark"
          className={`${css.menuDown} mt-10`}
          selectedKeys={selectedItem}
          onClick={handleMenuItemClick}
          items={personalItems}
        />
      </Sider>
      <Notifications
        collapsed={collapsed}
        notificationVisible={notificationVisible}
        setNotificationVisible={setNotificationVisible}
      />
    </div>
  );
};

export default NavBar;
