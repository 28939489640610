import React, { FC, JSX } from "react";
import { ReactComponent as StarIcon } from "../../assets/icons/CustomTable/star.svg";
import Tag from "../Tag/Tag";
import css from "./ListItem.module.css";

interface IListItemProps {
  value?: number | string | string[];
  label: string;
  isTag?: boolean,
  isMark?: boolean,
  className?: string;
}

const renderTags = (
  items: string[],
): JSX.Element[] => (
  items.map((item: string): JSX.Element => (
    <Tag
      key={item}
      tag={item}
      isTableTag
      className="mr-2"
    />
  ))
);

const renderMarks = (mark: number): JSX.Element => (
  <span className="flex items-center">
    <StarIcon className="mr-2" />{mark}{renderMarkDefinition(mark)}
  </span>
);

const renderMarkDefinition = (mark: number): string => {
  switch (mark) {
    case 5:
      return " — Отлично";
    case 4:
      return " — Удовлетворен";
    case 3:
      return " — Скорее удовлетворен";
    case 2:
      return " — Скорее неудовлетворен";
    case 1:
      return " — Неудовлетворен";
    default:
      return "";
  }
};

const ListItem: FC<IListItemProps> = ({
  value,
  label,
  isTag,
  isMark,
  className,
}): false | JSX.Element => {
  const isValueRendered = !isTag && !isMark;
  return (
    <li className="flex items-center mb-2">
      <div className={`${className} ${css.listItemLabel}`}>
        {label}
      </div>
      <div className="flex flex-wrap">
        {isTag && (value ? renderTags(value as string[]) : "-")}
        {isMark && (value ? renderMarks(value as number) : "-")}
        {isValueRendered && (value ? value : "-")}
      </div>
    </li>
  );
};

export default ListItem;
