import {
  FC,
  JSX,
} from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Form/close_icon.svg";
import { CustomButton } from "../../../components/CustomButton/CustomButton";

interface IMarkFormHeaderProps {
  closeForm: () => void;
}

const MarkFormHeader: FC<IMarkFormHeaderProps> = ({
  closeForm,
}): JSX.Element => {
  return (
    <div className="flex items-center justify-between mb-8">
      <h1 className="header">
        Добавить оценку поставщику
      </h1>
      <CustomButton
        className="p-0 gap-0"
        type="link"
        ghost
        text=""
        onClick={closeForm}
      >
        <CloseIcon />
      </CustomButton>
    </div>
  );
};

export default MarkFormHeader;
