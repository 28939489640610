import {
  FC,
  JSX,
} from "react";
import { DocumentListResultsType } from "app/types";
import RenderLabel from "./RatingFormItems";
import { Form, Rate } from "antd";

interface IRatingFormItemsProps {
  contractorMarkCriteria: DocumentListResultsType[];
}

const RatingFormItems: FC<IRatingFormItemsProps> = ({ contractorMarkCriteria }): JSX.Element[] =>
  contractorMarkCriteria?.map(({
    uuid,
    className = "mb-5",
    mark_criteria_name,
    isRate = true,
  }: DocumentListResultsType, ) => (
    <Form.Item
      key={uuid}
      name={`mark_criteria_name_${uuid}`}
      label={<RenderLabel label={mark_criteria_name} isRate={isRate} />}
      className={className}
      rules={[
        {
          required: true,
          message: "Пожалуйста, выставьте оценку"
        },
      ]}
    >
      <Rate />
    </Form.Item>
  )
);

export default RatingFormItems;
