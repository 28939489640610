import {
  FC,
  JSX,
  CSSProperties,
} from "react";
import css from "./Tag.module.css";

interface ITagProps {
  tag: string;
  style?: CSSProperties;
  color?: string;
  className?: string;
  isTableTag?: boolean;
}

const Tag: FC<ITagProps> = ({
  tag,
  style,
  color,
  className,
  isTableTag,
}): JSX.Element => {
  const nonBreakingTag: string = tag.replace(/ /g, "\u00a0");
  const breakingTag: string = tag?.split(" ")?.join("\u00a0");
  const tableTagStyle: string = isTableTag ? css.tableTag : css.defaultTag;
  const tagStyles: CSSProperties = {
    ...style,
    color: color && "white",
    borderColor: color,
    backgroundColor: color,
  };

  return (
    <span
      style={tagStyles}
      className={`${className} ${tableTagStyle} mb-1`}
    >
      {breakingTag}
    </span>
  );
};

export default Tag;
