import axios from "../utils/axios";
import {
  ChangePasswordType,
  FilterProfileClientsType,
  LoginDataType,
  UsersListingFilterType,
  AllUsersListingType,
} from "app/types";
import { AxiosResponse } from "axios";

const API_BASE: string | undefined = process.env.REACT_APP_API_TASKS_URL?.length
  ? process.env.REACT_APP_API_TASKS_URL
  : "https://dev.pipeline.smbconnect.ru";
const AUTH_REALM: string | undefined = process.env.REACT_APP_API_AUTH_REALM?.length
  ? process.env.REACT_APP_API_AUTH_REALM
  : "bk-dev";
const CLIENT_UUID: string | undefined = process.env.REACT_APP_API_CLIENT_UUID?.length
  ? process.env.REACT_APP_API_CLIENT_UUID
  : "e1d60bd7-254b-414d-a368-d0c2cacad7de";
const CLIENT_SECRET: string | undefined = process.env.REACT_APP_API_CLIENT_SECRET?.length
  ? process.env.REACT_APP_API_CLIENT_SECRET
  : "hVmB4SG1k5DfKNZoSn6KswvOuKPdaPRv";

const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_BASE;

const scope: string = "openid";
const clientId: string = "bc";
const grantType: string = "password";
const grantTypeRefresh: string = "refresh_token";

// Ручка авторизации
export const login = async (username: string, password: string): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantType,
    client_secret: CLIENT_SECRET,
    scope,
    username,
    password
  };

  return axios.POST_LOGIN(url, `auth/realms/${AUTH_REALM}/protocol/openid-connect/token`, loginData);
};

// Ручка обновления токена
export const updateToken = async (refreshToken: string | null): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantTypeRefresh,
    client_secret: CLIENT_SECRET,
    scope,
    refresh_token: refreshToken,
  };

  return axios.POST_LOGIN(url, `auth/realms/${AUTH_REALM}/protocol/openid-connect/token`, loginData);
};

// Ручка получения информации об авторизованном пользователе по токену
export const fetchUserInfo = async (): Promise<any> => {
  return axios.POST_LOGIN(url, `auth/realms/${AUTH_REALM}/protocol/openid-connect/userinfo`);
};

// Ручка обновления информации об авторизованном пользователе
export const updateUserAttrInfo = async (userId?: string, body?: any): Promise<any> => {
  return axios.PUT(url, `api/mdm/user/v1/edit/${userId}`, body );
};

// Ручка получения данных выбранного пользователя в разделе исполнители
export const fetchSelectedUser = async (userId?: string | null): Promise<any> => {
  return axios.GET(url, `api/mdm/user/v1/${userId}`);
};

// Ручка получения подробной информации об авторизованном пользователе по uuid пользователя
// Убрана из профиля т.к отрабатывает только для роли админа
export const getUserFullInfo = async (userId?: string): Promise<any> => {
  return axios.GET_KEYCLOACK(url, `auth/admin/realms/${AUTH_REALM}/users/${userId}`);
};

// Ручка получения списка доступных ролей
export const getUserRoleList = async (): Promise<any> => {
  return axios.GET_KEYCLOACK(url, `auth/admin/realms/${AUTH_REALM}/clients/${CLIENT_UUID}/roles`);
};

// Ручка получения конкретной роли
export const getUserRole = async (roleName: string | null): Promise<any> => {
  return axios.GET_KEYCLOACK(
    url,
    `auth/admin/realms/${AUTH_REALM}/clients/${CLIENT_UUID}/roles/${roleName}/users`,
  );
};

// Ручка расшифровки токена
export const getUserTokenDecryption = async (): Promise<any> => {
  return axios.GET_KEYCLOACK(url, `auth/realms/${AUTH_REALM}/protocol/openid-connect/certs`);
};

//Ручка получения списка пользователей
export const getUsersListing = async (
  params?: UsersListingFilterType | string | null
): Promise<AxiosResponse<AllUsersListingType>> => {
  const urlWithParams: string = typeof params === "string" ? `?${params}` : "";
  const config: UsersListingFilterType | null = typeof params === "object" ? params : null;

  return axios.GET(url, `api/mdm/user/v1/all_users${urlWithParams}`, config);
};

//ручка получения листинга исполнителей
export const getPerformersListing = async (): Promise<any> => {
  return axios.GET_KEYCLOACK(url, `auth/admin/realms/${AUTH_REALM}/users`);
};

//ручка получения роли исполнителей
export const getPerformersRoles = async (id: string): Promise<any> => {
  return axios.GET_KEYCLOACK(url, `auth/admin/realms/${AUTH_REALM}/users/${id}/role-mappings`);
};

//ручка получения обслуживаемых клиентов
export const getClientsServedListing = async (
  uuid: string,
  params: null | FilterProfileClientsType = null
): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/client?responsible_reference.uuid=${uuid}`, params);
};

//ручка восстановление (изменение) пароля
export const changePassword = async (params: ChangePasswordType): Promise<any> => {
  return axios.PUT(url, "api/mdm/user/v1/reset_password_email", params);
};
