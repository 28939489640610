import {
  FC,
  JSX,
} from "react";
import ListItem from "../../../components/ListItem/ListItem";
import { RelationDocumentType } from "app/types";

interface IMarkCardInfoProps {
  author: RelationDocumentType[];
  comment: string;
  deadline_criteria: number;
  expectation_criteria: number;
  communication_criteria: number;
  material_security_criteria: number;
  activityRegion: RelationDocumentType[];
  supplierCategories: RelationDocumentType[];
}

const MarkCardInfo: FC<IMarkCardInfoProps> = ({
  author,
  comment,
  deadline_criteria,
  expectation_criteria,
  communication_criteria,
  material_security_criteria,
  activityRegion,
  supplierCategories,
}): JSX.Element => {
  const markInfoItems = [
    { label: "Cоблюдение сроков", value: deadline_criteria, type: "mark", },
    { label: "Соответствие ожиданиям", value: expectation_criteria, type: "mark", },
    { label: "Качество коммуникации (соблюдение договоренностей)", value: communication_criteria, type: "mark", },
    { label: "Материально-техническая обеспеченность поставщика", value: material_security_criteria, type: "mark", },
    { label: "Автор оценки", value: author?.[0]?.label },
    { label: "Комментарий", value: comment },
  ];

  const customerInfoItems = [
    {
      label: "Регион деятельности",
      value: activityRegion?.flatMap(({ region_name }) => region_name),
      type: "tag",
    },
    {
      label: "Категория",
      value: supplierCategories?.flatMap(({ category_name }) => category_name),
      type: "tag",
    },
  ];

  const renderMarkCardBlock = (
    blockHeader: string,
    blockValues: any,
  ): false | JSX.Element => (
    !!blockValues?.length && (
      <div className="mb-4 p-5 bg-white rounded-xl">
        <h2 className="sub-header mb-5">
          {blockHeader}
        </h2>
        {blockValues?.map(({ label, value, type }: any): JSX.Element => (
          <ListItem
            key={label}
            label={label}
            value={value}
            isTag={type === "tag"}
            isMark={type === "mark"}
          />
        ))}
      </div>
    )
  );

  return (
    <div>
      {renderMarkCardBlock("Об оценке", markInfoItems)}
      {renderMarkCardBlock("Заказчик", customerInfoItems)}
    </div>
  );
};

export default MarkCardInfo;
