import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SCHEMA_LIST_SUCCESS,
  GET_DOCUMENT_SCHEMA_LIST_FAILURE,
} from "../constants/actionTypes";
import { DocumentListResultsType, DocumentType } from "app/types";
import * as DocumentsAPI from "../api/document.api";

export type DocumentsActionTypes =
  | GetDocumentSuccessType
  | GetDocumentFailureType
  | GetDocumentSchemaListSuccessType
  | GetDocumentSchemaListFailureType;

// Логика получения данных документа
export type GetDocumentSuccessType = {
  type: typeof GET_DOCUMENT_SUCCESS;
  payload: {
    document: DocumentType;
  };
};

const getDocumentSuccess = (document: DocumentType): GetDocumentSuccessType => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: {
    document
  }
});

export type GetDocumentFailureType = {
  type: typeof GET_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentFailure = (error?: any): GetDocumentFailureType => ({
  type: GET_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const getDocData = (schemaName: string, docUuid: string):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  DocumentsActionTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentsAPI.getDocument(schemaName, docUuid);

    dispatch(getDocumentSuccess(response.data.fields));
  } catch (error: any) {
    dispatch(getDocumentFailure(error.message));
  }
};

// Логика получения списка схем
export type GetDocumentSchemaListSuccessType = {
  type: typeof GET_DOCUMENT_SCHEMA_LIST_SUCCESS;
  payload: {
    schemasList: DocumentListResultsType[];
  };
};

const getDocumentSchemaListSuccess = (schemasList: DocumentListResultsType[]): GetDocumentSchemaListSuccessType => ({
  type: GET_DOCUMENT_SCHEMA_LIST_SUCCESS,
  payload: {
    schemasList
  }
});

export type GetDocumentSchemaListFailureType = {
  type: typeof GET_DOCUMENT_SCHEMA_LIST_FAILURE;
  payload: {
    error: any;
  };
};

const getDocumentSchemaListFailure = (error?: any): GetDocumentSchemaListFailureType => ({
  type: GET_DOCUMENT_SCHEMA_LIST_FAILURE,
  payload: {
    error
  }
});

export const getSchemasList = (params?: any):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentsActionTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentsAPI.getSchemas(params);

    dispatch(getDocumentSchemaListSuccess(response.data.results));
  } catch (error: any) {
    dispatch(getDocumentSchemaListFailure(error.message));
  }
};
