const translations: Record<string, string> = {
  inn: "ИНН",
  short_name: "Заказчик",
  marks_count: "Кол-во оценок",
  average_mark: "Средняя оценка",
  company_name: "Контрагент",
  fish_criteria: "Самая рыбка",
  kitten_criteria: "Самый котик",
  vtb_average_mark: "Оценка ВТБ",
  supplier_categories: "Категория",
  central_bank_average_mark: "Оценка ЦБ",
  deadline_criteria: "Cоблюдение сроков",
  expectation_criteria: "Соответствие ожиданиям",
  communication_criteria: "Качество коммуникации (соблюдение договоренностей)",
  material_security_criteria: "Материально-техническая обеспеченность поставщика",
};

const createTableTitle = (key: string): string => key.charAt(0).toUpperCase() + key.slice(1);

export const translateTableTitle = (key: string): string => translations[key] || createTableTitle(key);
